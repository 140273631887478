<template>
  <div class="about-img">

    <img
      src="@/assets/image/about/details-banner.png"
      alt=""
    />

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.about-img {
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1200px) {
  .about-img,
  .about-img img {
    width: 1200px;
  }
}
</style>
